<template>
  <div class="adminContent">
    <h1 class="display-1 adminHeadline">Velkommen til DDU administration</h1>
    <div class="adminButtons">
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/medlemmer'">
        <div class="center">
          <va-icon name="perm_identity" size="6rem" style="align-items: baseline;" />
          <p class="display-6">Medlemmer</p>
        </div>
      </router-link>
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/klubber'">
        <div class="center">
          <va-icon name="radar" size="6rem" />
          <p class="display-6">Klubber</p>
        </div>
      </router-link>
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/indlaeg'">
        <div class="center">
          <va-icon name="note_alt" size="6rem" />
          <p class="display-6">Indlæg</p>
        </div>
      </router-link>
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/events'">
        <div class="center">
          <va-icon name="event" size="6rem" />
          <p class="display-6">Arrangementer</p>
        </div>
      </router-link>
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/staevner'">
        <div class="center">
          <va-icon name="sports_martial_arts" size="5rem" />
          <p class="display-6">Stævner</p>
        </div>
      </router-link>
      <router-link class="adminCard btn" tag="div" :to="'/' + `${nEfYlAz}` + '/turneringer'">
        <div class="center">
          <va-icon name="date_range" size="6rem" />
          <p class="display-6">Turneringer</p>
        </div>
      </router-link>
    </div>

  </div>
</template>

<script langt="ts">
import { Vue } from 'vue-class-component'
import { admZubPth } from '@/components/Utility/aXdYmZ'

export default class adminHome extends Vue {
  nEfYlAz = admZubPth.toString()
}
</script>

<style lang="sass" scoped>
.adminContent
  width: 100%

.adminHeadline
  margin-bottom: 100px

.adminButtons
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center
  align-items: center
  gap: 25px
  width: 50%
  margin: 0 auto

.adminCard
  border-radius: 5px
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  height: 200px
  width: 200px
  text-align: center
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-around

.center
  text-align:  center

.adminCard:active
  transform: translateY(4px)

</style>
